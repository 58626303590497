<template>
    <div>
        <v-row class="justify-end">
            <v-col cols="12" :md="showCampusSpecificProprties ? 4 : 8"  class="pb-0 pt-6 d-flex">
                <v-switch v-model="showCampusSpecificProprties"
                            label="Campus Specific Properties"
                            class="mr-4"
                            inset
                            @change="getMatchedProperties"
                ></v-switch>
                <v-select
                    v-if="!showCampusSpecificProprties"
                    outlined dense label="Distance Radius (Miles)"
                    :items="distances"
                    v-model="mapRadius"
                    suffix="Miles"
                    @change="getMatchedProperties"
                ></v-select>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="4" class="pt-0">
                <div v-if="isLoadingPropertyMatches">
                    <div class="pt-8">
                      <v-skeleton-loader type="card" v-for="n of 2" :key="n"></v-skeleton-loader>
                    </div>
                </div>

                <StudentPropertyMatches v-if="!isLoadingPropertyMatches"
                                        :student="student"
                                        :property-units="matchedPropertyUnits"
                                        v-on:getProperties="getMatchedProperties"
                                        @propertyReferred="onPropertyReferred($event)"
                                        @propertyUnitClicked="propertyMarker=$event"
                />
            </v-col>
            <v-col cols="12" md="8" class="pt-8">
                <GoogleMaps v-if="markers.length > 0"
                            :markers="markers"
                            :student="student"
                            v-on:getProperties="getProperties"
                            :selected-marker="propertyMarker"
                ></GoogleMaps>
                <v-skeleton-loader v-else type="card, card"></v-skeleton-loader>
            </v-col>
        </v-row>
        <hr />

        <StudentCombinedPropertyUnits :student="student"
                                      :is-loading-referred-properties="isLoadingReferredProperties"
                                      :referred-property-units="referredPropertyUnits"
                                      :campus="student.campus"
                                      @removeReferredProperty="onRemoveReferredProperty($event)"
                                      v-on:fetchReferredPropertyUnits="getReferredPropertyUnits"

        />
    </div>
</template>

<script>
    import StudentPropertyMatches from "./StudentPropertyMatches";
    import StudentCombinedPropertyUnits from "./StudentCombinedPropertyUnits";
    import GoogleMaps from "../../components/GoogleMaps";
    import StudentService from "../../services/student.service";
    import {calculateGeoDistances} from "@/utils/utils";

    export default {
        name: "StudentProperties",
        props: ['student'],
        watch: {
            matchedPropertyUnits() {
                this.refreshMarkers();
            },
            student() {
                this.getMatchedProperties();
                this.getReferredPropertyUnits();
            },
            search(val) {
                if (val === '') return;
                this.debouncedSearch(val);
            }
        },
        data() {
            return {
                showMap: false,
                mapRadius: 10,
                distances: [5, 10, 20, 30],
                markers: [],
                propertyMarker: {},
                distanceFilter: '',
                search: '',
                isLoadingPropertyMatches: false,
                isLoadingReferredProperties: false,
                matchedPropertyUnits: [],
                referredPropertyUnits: [],
                showCampusSpecificProprties: true
            }
        },
        components: {
            GoogleMaps,
            StudentPropertyMatches,
            StudentCombinedPropertyUnits
        },
        computed: {
            school() {
                const school = this.$store.getters.getSchools.filter(school => {
                    return Number(school.id) === Number(this.student.school_id)
                });

                return school[0]
            }
        },
        methods: {
            debouncedSearch(userInput) {
              // cancel pending call
              clearTimeout(this._timerId)

              // delay new call 500ms
              this._timerId = setTimeout(() => {
                this.searchApi(userInput)
              }, 500)
            },
            searchApi: async function () {
              this.getMatchedProperties();
            },
            getMatchedProperties: async function() {
                let params = `?appendProperty&removeReferredProperties&removeFavoriteProperties&removeImages&sortByPrice=asc&removeContactedProperties&studentId=${this.student.id}`;

                if (this.showCampusSpecificProprties) {
                  params = `${params}&campusId=${this.student.campus_id}`
                } else {
                  params = `${params}&distance=${this.mapRadius}`
                }
                if (this.student.preferred_bedrooms) {
                    params = `${params}&beds=${this.student.preferred_bedrooms}`
                }
                if (this.student.preferred_bathrooms) {
                    params = `${params}&baths=${this.student.preferred_bathrooms}`
                }
                if (this.search) {
                    params = `${params}&propertySearch=${this.search}`
                }

                this.isLoadingPropertyMatches = true;
                this.matchedPropertyUnits     = await StudentService.getStudentPropertyUnitMatches(params);
                this.isLoadingPropertyMatches = false;
            },
            getReferredPropertyUnits: async function() {
                this.isLoadingReferredProperties = true;
                this.referredPropertyUnits = await StudentService.getReferredPropertyUnits(this.student.id, '?sort=desc&appendInvoice');
                this.isLoadingReferredProperties = false;
            },
            refreshMarkers: async function() {
                this.markers = [];

                // add school coordinates
                this.markers.push({
                    position: {
                        lat: Number(this.student.campus.latitude),
                        lng: Number(this.student.campus.longitude)
                    },
                    icon: 'map-school.svg',
                    type: 'school',
                    school: this.student.campus
                });

                this.matchedPropertyUnits.forEach(item => {
                    this.markers.push({
                        position: {
                            lat: Number(item.property.latitude),
                            lng: Number(item.property.longitude)
                        },
                        icon:"map-building.svg",
                        type: 'propertyUnit',
                        propertyUnit: item,
                        distanceToSchool: this.getSchoolDistance(item)
                    });
                });
            },
            onPropertyReferred(referredProperty) {
                //remove from matchedProperties
                const index = this.matchedPropertyUnits.findIndex(mp => mp.id === referredProperty.property_unit_id)
                this.matchedPropertyUnits.splice(index, 1)

                    //and add to referredProperties
                    this.referredPropertyUnits.unshift(referredProperty)
            },
            onRemoveReferredProperty(referredPropertyId) {
                //remove from matchedProperties
                const index = this.referredPropertyUnits.findIndex(rp => rp.id === referredPropertyId)
                const deletedReferredProperty = this.referredPropertyUnits.splice(index, 1)

                if (!deletedReferredProperty) {
                    return;
                }

                //and add to referredProperties
                this.matchedPropertyUnits.unshift(deletedReferredProperty[0].property_unit)
            },
            getProperties() {
                this.getMatchedProperties();
                this.getReferredPropertyUnits();
            },
            getSchoolDistance(propertyUnit) {
              const distance = calculateGeoDistances(
                  Number(this.student.campus.latitude),
                  Number(this.student.campus.longitude),
                  Number(propertyUnit.property.latitude),
                  Number(propertyUnit.property.longitude)
              );

              return Number(distance).toFixed(0)
            },
        },
        beforeMount: async function() {
            this.getProperties();
        }
    }
</script>

<style scoped>
    p {
        color: black;
    }
</style>
